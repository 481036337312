// Generated by Framer (38fb4ea)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const ImageWithFX = withFX(Image);

const cycleOrder = ["PFgaGgL1i"];

const serializationHash = "framer-vOBl5"

const variantClassNames = {PFgaGgL1i: "framer-v-jk6162"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition2 = {delay: 0, duration: 6, ease: [0, 0, 1, 1], type: "tween"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 360, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image2, image3, link, width, ...props}) => { return {...props, DHNBtUu_X: image3 ?? props.DHNBtUu_X, nBIymNuk9: link ?? props.nBIymNuk9, SS3daNgLL: image2 ?? props.SS3daNgLL} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;image2?: {src: string; srcSet?: string};image3?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nBIymNuk9, SS3daNgLL, DHNBtUu_X, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "PFgaGgL1i", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={nBIymNuk9}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-jk6162", className, classNames)} framer-1ypjkjo`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PFgaGgL1i"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><ImageWithFX __framer__loop={animation} __framer__loopEffectEnabled __framer__loopRepeatDelay={0} __framer__loopRepeatType={"loop"} __framer__loopTransition={transition2} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} background={{alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(SS3daNgLL)}} className={"framer-hs4h5k"} data-framer-name={"Photo 2"} layoutDependency={layoutDependency} layoutId={"fq_fLTIcs"} style={{borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, transformPerspective: 1200}}/><ImageWithFX __framer__loop={animation} __framer__loopEffectEnabled __framer__loopRepeatDelay={0} __framer__loopRepeatType={"loop"} __framer__loopTransition={transition2} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} background={{alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(DHNBtUu_X)}} className={"framer-xb2qw6"} data-framer-name={"Photo 3"} layoutDependency={layoutDependency} layoutId={"mxpbGAx7P"} style={{borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, rotateY: 180, transformPerspective: 1200}}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vOBl5.framer-1ypjkjo, .framer-vOBl5 .framer-1ypjkjo { display: block; }", ".framer-vOBl5.framer-jk6162 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: wrap; gap: 40px; height: 190px; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: 280px; }", ".framer-vOBl5 .framer-hs4h5k, .framer-vOBl5 .framer-xb2qw6 { aspect-ratio: 1.4736842105263157 / 1; backface-visibility: hidden; flex: none; height: var(--framer-aspect-ratio-supported, 190px); left: 0px; position: absolute; right: 0px; top: 0px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-vOBl5.framer-jk6162 { gap: 0px; } .framer-vOBl5.framer-jk6162 > * { margin: 0px; margin-left: calc(40px / 2); margin-right: calc(40px / 2); } .framer-vOBl5.framer-jk6162 > :first-child { margin-left: 0px; } .framer-vOBl5.framer-jk6162 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 190
 * @framerIntrinsicWidth 280
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"nBIymNuk9":"link","SS3daNgLL":"image2","DHNBtUu_X":"image3"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerePRrQX0Nn: React.ComponentType<Props> = withCSS(Component, css, "framer-vOBl5") as typeof Component;
export default FramerePRrQX0Nn;

FramerePRrQX0Nn.displayName = "3D - CMS";

FramerePRrQX0Nn.defaultProps = {height: 190, width: 280};

addPropertyControls(FramerePRrQX0Nn, {nBIymNuk9: {title: "Link", type: ControlType.Link}, SS3daNgLL: {title: "Image 2", type: ControlType.ResponsiveImage}, DHNBtUu_X: {title: "Image 3", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerePRrQX0Nn, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})